<template>
  <header
    id="app-header"
    style="width:100%; height:5rem;display: flex;justify-content: center; align-items: center;"
  >
    White Label Feed Headerbar
  </header>
</template>

<script>
import UserProfileDropdown from '@/components/user/UserProfileDropdown';
import InfoHeaderBanner from '@/components/InfoHeaderBanner';

import { chatInit } from '../services/chatHelper.js';
export default {
  name: 'WhiteLabelFeedHeaderbar',
  components: {
    UserProfileDropdown,
    InfoHeaderBanner
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang="scss">
#app-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>
