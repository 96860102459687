import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e65e355 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _04d56f8a = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _49901d40 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _0ce89114 = () => interopDefault(import('../pages/client/index.vue' /* webpackChunkName: "pages/client/index" */))
const _3c6e312a = () => interopDefault(import('../pages/community-program/index.vue' /* webpackChunkName: "pages/community-program/index" */))
const _0ef73668 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1e7d76dc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _f168d8dc = () => interopDefault(import('../pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _02173008 = () => interopDefault(import('../pages/emailsignup.vue' /* webpackChunkName: "pages/emailsignup" */))
const _3234bd88 = () => interopDefault(import('../pages/funding/index.vue' /* webpackChunkName: "pages/funding/index" */))
const _04f4cb70 = () => interopDefault(import('../pages/headhunter.vue' /* webpackChunkName: "pages/headhunter" */))
const _3b6226c2 = () => interopDefault(import('../pages/headhunter-terms.vue' /* webpackChunkName: "pages/headhunter-terms" */))
const _56c3ba5d = () => interopDefault(import('../pages/indexOld.vue' /* webpackChunkName: "pages/indexOld" */))
const _2dd90241 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _005b9456 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _69666f0e = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _00e43c7f = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _268dcf6e = () => interopDefault(import('../pages/scout/index.vue' /* webpackChunkName: "pages/scout/index" */))
const _a3b81780 = () => interopDefault(import('../pages/scout-terms.vue' /* webpackChunkName: "pages/scout-terms" */))
const _b383a8ea = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _7f7408fe = () => interopDefault(import('../pages/startups/index.vue' /* webpackChunkName: "pages/startups/index" */))
const _4fc90762 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _02d672e6 = () => interopDefault(import('../pages/tools/index.vue' /* webpackChunkName: "pages/tools/index" */))
const _353c4eb4 = () => interopDefault(import('../pages/whiteLabelFeed/index.vue' /* webpackChunkName: "pages/whiteLabelFeed/index" */))
const _0ee561a9 = () => interopDefault(import('../pages/client/applicant/index.vue' /* webpackChunkName: "pages/client/applicant/index" */))
const _0a6e06d7 = () => interopDefault(import('../pages/client/followed-startups.vue' /* webpackChunkName: "pages/client/followed-startups" */))
const _41420cfb = () => interopDefault(import('../pages/client/my-jobs.vue' /* webpackChunkName: "pages/client/my-jobs" */))
const _acbf4036 = () => interopDefault(import('../pages/client/reset-password.vue' /* webpackChunkName: "pages/client/reset-password" */))
const _b0d0c558 = () => interopDefault(import('../pages/client/verify-email.vue' /* webpackChunkName: "pages/client/verify-email" */))
const _a92ba7d8 = () => interopDefault(import('../pages/community-program/applyIsSentModal.vue' /* webpackChunkName: "pages/community-program/applyIsSentModal" */))
const _a96f4ad4 = () => interopDefault(import('../pages/community-program/applyModal.vue' /* webpackChunkName: "pages/community-program/applyModal" */))
const _3f992e13 = () => interopDefault(import('../pages/external/featuredjobs/index.vue' /* webpackChunkName: "pages/external/featuredjobs/index" */))
const _333d8ad2 = () => interopDefault(import('../pages/funding/quiz/index.vue' /* webpackChunkName: "pages/funding/quiz/index" */))
const _a9ca6474 = () => interopDefault(import('../pages/investors/join.vue' /* webpackChunkName: "pages/investors/join" */))
const _bf114c3c = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Aarhus.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Aarhus" */))
const _1dc889d0 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Copenhagen" */))
const _0ee209f5 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Helsinki.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Helsinki" */))
const _609bf776 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Jutland.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Jutland" */))
const _a7e00f04 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Odense.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Odense" */))
const _2a9bf4d5 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Oslo.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Oslo" */))
const _e05ee240 = () => interopDefault(import('../pages/jobs/backend-developer-jobs-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/backend-developer-jobs-in-Stockholm" */))
const _7818a9a4 = () => interopDefault(import('../pages/jobs/business-development-roles-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/business-development-roles-in-Copenhagen" */))
const _00647c28 = () => interopDefault(import('../pages/jobs/business-development-roles-in-Finland.vue' /* webpackChunkName: "pages/jobs/business-development-roles-in-Finland" */))
const _3361bc61 = () => interopDefault(import('../pages/jobs/business-development-roles-in-Oslo.vue' /* webpackChunkName: "pages/jobs/business-development-roles-in-Oslo" */))
const _4458b8e6 = () => interopDefault(import('../pages/jobs/business-development-roles-in-Sweden.vue' /* webpackChunkName: "pages/jobs/business-development-roles-in-Sweden" */))
const _9a94ada8 = () => interopDefault(import('../pages/jobs/content-creator-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/content-creator-in-Copenhagen" */))
const _ad478d70 = () => interopDefault(import('../pages/jobs/content-creator-in-Denmark.vue' /* webpackChunkName: "pages/jobs/content-creator-in-Denmark" */))
const _6da93a8a = () => interopDefault(import('../pages/jobs/customer-success-roles-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/customer-success-roles-in-Copenhagen" */))
const _727b54aa = () => interopDefault(import('../pages/jobs/customer-success-roles-in-Denmark.vue' /* webpackChunkName: "pages/jobs/customer-success-roles-in-Denmark" */))
const _7719c3e8 = () => interopDefault(import('../pages/jobs/designer-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/designer-jobs-in-Copenhagen" */))
const _2b46fd6c = () => interopDefault(import('../pages/jobs/designer-jobs-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/designer-jobs-in-Stockholm" */))
const _43d5713a = () => interopDefault(import('../pages/jobs/devops-jobs-in-Denmark.vue' /* webpackChunkName: "pages/jobs/devops-jobs-in-Denmark" */))
const _b55c8e18 = () => interopDefault(import('../pages/jobs/e-commerce-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/e-commerce-jobs-in-Copenhagen" */))
const _109df036 = () => interopDefault(import('../pages/jobs/e-commerce-jobs-in-Sweden.vue' /* webpackChunkName: "pages/jobs/e-commerce-jobs-in-Sweden" */))
const _66f2dadd = () => interopDefault(import('../pages/jobs/financial-controller-jobs-in-Denmark.vue' /* webpackChunkName: "pages/jobs/financial-controller-jobs-in-Denmark" */))
const _3e3e7888 = () => interopDefault(import('../pages/jobs/foodtech-in-Sweden.vue' /* webpackChunkName: "pages/jobs/foodtech-in-Sweden" */))
const _89f6aa1e = () => interopDefault(import('../pages/jobs/foodtech-jobs-in-Denmark.vue' /* webpackChunkName: "pages/jobs/foodtech-jobs-in-Denmark" */))
const _0a0bf1ac = () => interopDefault(import('../pages/jobs/graphic-design-internship-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/graphic-design-internship-in-Copenhagen" */))
const _a8997940 = () => interopDefault(import('../pages/jobs/helper.js' /* webpackChunkName: "pages/jobs/helper" */))
const _6bda6040 = () => interopDefault(import('../pages/jobs/HR-roles-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/HR-roles-in-Copenhagen" */))
const _34d61d82 = () => interopDefault(import('../pages/jobs/HR-roles-in-Sweden.vue' /* webpackChunkName: "pages/jobs/HR-roles-in-Sweden" */))
const _6bad071b = () => interopDefault(import('../pages/jobs/internships-in-Denmark.vue' /* webpackChunkName: "pages/jobs/internships-in-Denmark" */))
const _a48c6c32 = () => interopDefault(import('../pages/jobs/jobs-in-tech-in-Malmo.vue' /* webpackChunkName: "pages/jobs/jobs-in-tech-in-Malmo" */))
const _46b50014 = () => interopDefault(import('../pages/jobs/JobSearch.vue' /* webpackChunkName: "pages/jobs/JobSearch" */))
const _18f4601c = () => interopDefault(import('../pages/jobs/marketing-internships-in-Aarhus.vue' /* webpackChunkName: "pages/jobs/marketing-internships-in-Aarhus" */))
const _3ad71452 = () => interopDefault(import('../pages/jobs/marketing-internships-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/marketing-internships-in-Copenhagen" */))
const _2b490fe2 = () => interopDefault(import('../pages/jobs/marketing-internships-in-Denmark.vue' /* webpackChunkName: "pages/jobs/marketing-internships-in-Denmark" */))
const _91bdf404 = () => interopDefault(import('../pages/jobs/marketing-internships-in-Finland.vue' /* webpackChunkName: "pages/jobs/marketing-internships-in-Finland" */))
const _25efb334 = () => interopDefault(import('../pages/jobs/marketing-internships-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/marketing-internships-in-Stockholm" */))
const _1f9ee2c2 = () => interopDefault(import('../pages/jobs/part-time-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/part-time-jobs-in-Copenhagen" */))
const _307f141f = () => interopDefault(import('../pages/jobs/part-time-jobs-in-Helsinki.vue' /* webpackChunkName: "pages/jobs/part-time-jobs-in-Helsinki" */))
const _b44eb802 = () => interopDefault(import('../pages/jobs/part-time-jobs-in-Oslo.vue' /* webpackChunkName: "pages/jobs/part-time-jobs-in-Oslo" */))
const _bc566c14 = () => interopDefault(import('../pages/jobs/part-time-jobs-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/part-time-jobs-in-Stockholm" */))
const _47b7bf30 = () => interopDefault(import('../pages/jobs/project-manager-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/project-manager-jobs-in-Copenhagen" */))
const _222397be = () => interopDefault(import('../pages/jobs/SaaS-jobs-in-Aarhus.vue' /* webpackChunkName: "pages/jobs/SaaS-jobs-in-Aarhus" */))
const _180d5dd7 = () => interopDefault(import('../pages/jobs/SaaS-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/SaaS-jobs-in-Copenhagen" */))
const _ee5fcf7e = () => interopDefault(import('../pages/jobs/SaaS-jobs-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/SaaS-jobs-in-Stockholm" */))
const _446d1689 = () => interopDefault(import('../pages/jobs/student-job-in-Aarhus.vue' /* webpackChunkName: "pages/jobs/student-job-in-Aarhus" */))
const _15f66782 = () => interopDefault(import('../pages/jobs/student-job-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/student-job-in-Copenhagen" */))
const _e7498e48 = () => interopDefault(import('../pages/jobs/student-job-in-Helsinki.vue' /* webpackChunkName: "pages/jobs/student-job-in-Helsinki" */))
const _5b52a188 = () => interopDefault(import('../pages/jobs/student-job-in-Oslo.vue' /* webpackChunkName: "pages/jobs/student-job-in-Oslo" */))
const _7d05864e = () => interopDefault(import('../pages/jobs/student-job-in-Stockholm.vue' /* webpackChunkName: "pages/jobs/student-job-in-Stockholm" */))
const _4ecd44f0 = () => interopDefault(import('../pages/jobs/student-jobs-in-Sweden.vue' /* webpackChunkName: "pages/jobs/student-jobs-in-Sweden" */))
const _511cd4fd = () => interopDefault(import('../pages/jobs/ux-ui-designer-jobs-in-Copenhagen.vue' /* webpackChunkName: "pages/jobs/ux-ui-designer-jobs-in-Copenhagen" */))
const _81b7f1d2 = () => interopDefault(import('../pages/jobs/ux-ui-designer-jobs-in-Denmark.vue' /* webpackChunkName: "pages/jobs/ux-ui-designer-jobs-in-Denmark" */))
const _8b3b0e82 = () => interopDefault(import('../pages/jobs/ux-ui-designer-jobs-in-Sweden.vue' /* webpackChunkName: "pages/jobs/ux-ui-designer-jobs-in-Sweden" */))
const _3cc7dab3 = () => interopDefault(import('../pages/partners/danske-bank-growth.vue' /* webpackChunkName: "pages/partners/danske-bank-growth" */))
const _6b2093a1 = () => interopDefault(import('../pages/scout/CountUp.vue' /* webpackChunkName: "pages/scout/CountUp" */))
const _2688ab30 = () => interopDefault(import('../pages/scout/ScoutCustomers.vue' /* webpackChunkName: "pages/scout/ScoutCustomers" */))
const _6e004e22 = () => interopDefault(import('../pages/scout/ScoutHero.vue' /* webpackChunkName: "pages/scout/ScoutHero" */))
const _0bba085d = () => interopDefault(import('../pages/scout/ScoutKeypoints.vue' /* webpackChunkName: "pages/scout/ScoutKeypoints" */))
const _411e1bea = () => interopDefault(import('../pages/scout/ScoutStartups.vue' /* webpackChunkName: "pages/scout/ScoutStartups" */))
const _17c2d759 = () => interopDefault(import('../pages/scout/ScoutWays.vue' /* webpackChunkName: "pages/scout/ScoutWays" */))
const _0458aa26 = () => interopDefault(import('../pages/startups/career-page.vue' /* webpackChunkName: "pages/startups/career-page" */))
const _55caf585 = () => interopDefault(import('../pages/startups/faq.vue' /* webpackChunkName: "pages/startups/faq" */))
const _4523da6a = () => interopDefault(import('../pages/startups/join.vue' /* webpackChunkName: "pages/startups/join" */))
const _77fe7c9c = () => interopDefault(import('../pages/startups/white-label-feed.vue' /* webpackChunkName: "pages/startups/white-label-feed" */))
const _f31eacc8 = () => interopDefault(import('../pages/client/applicant/settings.vue' /* webpackChunkName: "pages/client/applicant/settings" */))
const _1c77d3e0 = () => interopDefault(import('../pages/client/investors/new.vue' /* webpackChunkName: "pages/client/investors/new" */))
const _77751107 = () => interopDefault(import('../pages/client/startups/edit.vue' /* webpackChunkName: "pages/client/startups/edit" */))
const _0c1b14d4 = () => interopDefault(import('../pages/client/startups/jobs/index.vue' /* webpackChunkName: "pages/client/startups/jobs/index" */))
const _4da8c633 = () => interopDefault(import('../pages/client/startups/new.vue' /* webpackChunkName: "pages/client/startups/new" */))
const _0c51043c = () => interopDefault(import('../pages/client/startups/profile.vue' /* webpackChunkName: "pages/client/startups/profile" */))
const _57567020 = () => interopDefault(import('../pages/client/startups/settings.vue' /* webpackChunkName: "pages/client/startups/settings" */))
const _29eef1f6 = () => interopDefault(import('../pages/funding/quiz/results.vue' /* webpackChunkName: "pages/funding/quiz/results" */))
const _d53a8b38 = () => interopDefault(import('../pages/client/startups/jobs/new.vue' /* webpackChunkName: "pages/client/startups/jobs/new" */))
const _58a46046 = () => interopDefault(import('../pages/careers/jobs/_id/index.vue' /* webpackChunkName: "pages/careers/jobs/_id/index" */))
const _7b384bac = () => interopDefault(import('../pages/client/investors/_investor/index.vue' /* webpackChunkName: "pages/client/investors/_investor/index" */))
const _53649ffe = () => interopDefault(import('../pages/client/startups/_company/index.vue' /* webpackChunkName: "pages/client/startups/_company/index" */))
const _19903d3a = () => interopDefault(import('../pages/jobs/location/_country/index.vue' /* webpackChunkName: "pages/jobs/location/_country/index" */))
const _b925f88e = () => interopDefault(import('../pages/startups/joblistwidget/_id.vue' /* webpackChunkName: "pages/startups/joblistwidget/_id" */))
const _e332dd5c = () => interopDefault(import('../pages/whiteLabelFeed/jobs/_id/index.vue' /* webpackChunkName: "pages/whiteLabelFeed/jobs/_id/index" */))
const _673f5be2 = () => interopDefault(import('../pages/client/investors/_investor/edit.vue' /* webpackChunkName: "pages/client/investors/_investor/edit" */))
const _9b63f0fe = () => interopDefault(import('../pages/client/investors/_investor/profile.vue' /* webpackChunkName: "pages/client/investors/_investor/profile" */))
const _70f9d57b = () => interopDefault(import('../pages/client/investors/_investor/settings.vue' /* webpackChunkName: "pages/client/investors/_investor/settings" */))
const _30931d1e = () => interopDefault(import('../pages/client/investors/_investor/startups/index.vue' /* webpackChunkName: "pages/client/investors/_investor/startups/index" */))
const _07cdb460 = () => interopDefault(import('../pages/client/investors/_investor/users.vue' /* webpackChunkName: "pages/client/investors/_investor/users" */))
const _1441da96 = () => interopDefault(import('../pages/client/startups/_company/applicants.vue' /* webpackChunkName: "pages/client/startups/_company/applicants" */))
const _780e7db4 = () => interopDefault(import('../pages/client/startups/_company/blog.vue' /* webpackChunkName: "pages/client/startups/_company/blog" */))
const _d19b0b14 = () => interopDefault(import('../pages/client/startups/_company/deals/index.vue' /* webpackChunkName: "pages/client/startups/_company/deals/index" */))
const _4ae48b8e = () => interopDefault(import('../pages/client/startups/_company/edit.vue' /* webpackChunkName: "pages/client/startups/_company/edit" */))
const _38050928 = () => interopDefault(import('../pages/client/startups/_company/funding/index.vue' /* webpackChunkName: "pages/client/startups/_company/funding/index" */))
const _4870a246 = () => interopDefault(import('../pages/client/startups/_company/jobs/index.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/index" */))
const _0634a855 = () => interopDefault(import('../pages/client/startups/_company/profile.vue' /* webpackChunkName: "pages/client/startups/_company/profile" */))
const _cc3161b2 = () => interopDefault(import('../pages/client/startups/_company/settings.vue' /* webpackChunkName: "pages/client/startups/_company/settings" */))
const _ce630b98 = () => interopDefault(import('../pages/client/startups/_company/users.vue' /* webpackChunkName: "pages/client/startups/_company/users" */))
const _5018cd2a = () => interopDefault(import('../pages/client/startups/_company/jobs/new.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/new" */))
const _40641c19 = () => interopDefault(import('../pages/client/investors/_investor/startups/_id.vue' /* webpackChunkName: "pages/client/investors/_investor/startups/_id" */))
const _7ee8a25e = () => interopDefault(import('../pages/client/startups/_company/deals/_id.vue' /* webpackChunkName: "pages/client/startups/_company/deals/_id" */))
const _4d692e58 = () => interopDefault(import('../pages/client/startups/_company/funding/_id.vue' /* webpackChunkName: "pages/client/startups/_company/funding/_id" */))
const _c5365ff4 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/index.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/index" */))
const _54f6fa57 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/boost.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/boost" */))
const _4fdeabb6 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/boosted.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/boosted" */))
const _d0cb8af4 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/edit.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/edit" */))
const _3daa2388 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/error.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/error" */))
const _408a0680 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/headhunter-posted.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/headhunter-posted" */))
const _35d332d4 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/hh-steps.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/hh-steps" */))
const _2a26ac44 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/max-posted.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/max-posted" */))
const _3ead4d48 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/monetise.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/monetise" */))
const _6396fe3b = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/posted.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/posted" */))
const _bffe8948 = () => interopDefault(import('../pages/client/startups/_company/jobs/_job/posted-via-invoice.vue' /* webpackChunkName: "pages/client/startups/_company/jobs/_job/posted-via-invoice" */))
const _6ab975c4 = () => interopDefault(import('../pages/jobs/location/_country/_city/index.vue' /* webpackChunkName: "pages/jobs/location/_country/_city/index" */))
const _05255b0c = () => interopDefault(import('../pages/deals/_id.vue' /* webpackChunkName: "pages/deals/_id" */))
const _6d36fc20 = () => interopDefault(import('../pages/funding/_id.vue' /* webpackChunkName: "pages/funding/_id" */))
const _2ff514ac = () => interopDefault(import('../pages/jobs/_id/index.vue' /* webpackChunkName: "pages/jobs/_id/index" */))
const _689b4f5a = () => interopDefault(import('../pages/profile-settings/_id.vue' /* webpackChunkName: "pages/profile-settings/_id" */))
const _64c36dec = () => interopDefault(import('../pages/startups/_id/index.vue' /* webpackChunkName: "pages/startups/_id/index" */))
const _570ff6ce = () => interopDefault(import('../pages/tools/_id.vue' /* webpackChunkName: "pages/tools/_id" */))
const _7c1ed246 = () => interopDefault(import('../pages/jobs/_id/resend-applicants.vue' /* webpackChunkName: "pages/jobs/_id/resend-applicants" */))
const _14ab9288 = () => interopDefault(import('../pages/jobs/_id/welcome.vue' /* webpackChunkName: "pages/jobs/_id/welcome" */))
const _30473ffc = () => interopDefault(import('../pages/startups/_id/welcome.vue' /* webpackChunkName: "pages/startups/_id/welcome" */))
const _2e1dbe1a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _73e0d7a3 = () => interopDefault(import('../pages/index/AnimatedImage.vue' /* webpackChunkName: "pages/index/AnimatedImage" */))
const _a9b5bbda = () => interopDefault(import('../pages/index/ExploreByCategory.vue' /* webpackChunkName: "pages/index/ExploreByCategory" */))
const _c2584fee = () => interopDefault(import('../pages/index/FeaturedStartups.vue' /* webpackChunkName: "pages/index/FeaturedStartups" */))
const _991cbaa6 = () => interopDefault(import('../pages/index/homePageHelpers.js' /* webpackChunkName: "pages/index/homePageHelpers" */))
const _44b9edcd = () => interopDefault(import('../pages/index/HomePageHero.vue' /* webpackChunkName: "pages/index/HomePageHero" */))
const _314ab97a = () => interopDefault(import('../pages/index/PopularStartups.vue' /* webpackChunkName: "pages/index/PopularStartups" */))
const _eee1dc30 = () => interopDefault(import('../pages/index/StartupCarousel.vue' /* webpackChunkName: "pages/index/StartupCarousel" */))
const _487ce6d0 = () => interopDefault(import('../pages/index/StartupLogos.vue' /* webpackChunkName: "pages/index/StartupLogos" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e65e355,
    name: "about"
  }, {
    path: "/blog",
    component: _04d56f8a,
    name: "blog"
  }, {
    path: "/careers",
    component: _49901d40,
    name: "careers"
  }, {
    path: "/client",
    component: _0ce89114,
    name: "client"
  }, {
    path: "/community-program",
    component: _3c6e312a,
    name: "community-program"
  }, {
    path: "/contact",
    component: _0ef73668,
    name: "contact"
  }, {
    path: "/dashboard",
    component: _1e7d76dc,
    name: "dashboard"
  }, {
    path: "/deals",
    component: _f168d8dc,
    name: "deals"
  }, {
    path: "/emailsignup",
    component: _02173008,
    name: "emailsignup"
  }, {
    path: "/funding",
    component: _3234bd88,
    name: "funding"
  }, {
    path: "/headhunter",
    component: _04f4cb70,
    name: "headhunter"
  }, {
    path: "/headhunter-terms",
    component: _3b6226c2,
    name: "headhunter-terms"
  }, {
    path: "/indexOld",
    component: _56c3ba5d,
    name: "indexOld"
  }, {
    path: "/jobs",
    component: _2dd90241,
    name: "jobs"
  }, {
    path: "/partners",
    component: _005b9456,
    name: "partners"
  }, {
    path: "/pricing",
    component: _69666f0e,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _00e43c7f,
    name: "privacy-policy"
  }, {
    path: "/scout",
    component: _268dcf6e,
    name: "scout"
  }, {
    path: "/scout-terms",
    component: _a3b81780,
    name: "scout-terms"
  }, {
    path: "/settings",
    component: _b383a8ea,
    name: "settings"
  }, {
    path: "/startups",
    component: _7f7408fe,
    name: "startups"
  }, {
    path: "/terms",
    component: _4fc90762,
    name: "terms"
  }, {
    path: "/tools",
    component: _02d672e6,
    name: "tools"
  }, {
    path: "/whiteLabelFeed",
    component: _353c4eb4,
    name: "whiteLabelFeed"
  }, {
    path: "/client/applicant",
    component: _0ee561a9,
    name: "client-applicant"
  }, {
    path: "/client/followed-startups",
    component: _0a6e06d7,
    name: "client-followed-startups"
  }, {
    path: "/client/my-jobs",
    component: _41420cfb,
    name: "client-my-jobs"
  }, {
    path: "/client/reset-password",
    component: _acbf4036,
    name: "client-reset-password"
  }, {
    path: "/client/verify-email",
    component: _b0d0c558,
    name: "client-verify-email"
  }, {
    path: "/community-program/applyIsSentModal",
    component: _a92ba7d8,
    name: "community-program-applyIsSentModal"
  }, {
    path: "/community-program/applyModal",
    component: _a96f4ad4,
    name: "community-program-applyModal"
  }, {
    path: "/external/featuredjobs",
    component: _3f992e13,
    name: "external-featuredjobs"
  }, {
    path: "/funding/quiz",
    component: _333d8ad2,
    name: "funding-quiz"
  }, {
    path: "/investors/join",
    component: _a9ca6474,
    name: "investors-join"
  }, {
    path: "/jobs/backend-developer-jobs-in-Aarhus",
    component: _bf114c3c,
    name: "jobs-backend-developer-jobs-in-Aarhus"
  }, {
    path: "/jobs/backend-developer-jobs-in-Copenhagen",
    component: _1dc889d0,
    name: "jobs-backend-developer-jobs-in-Copenhagen"
  }, {
    path: "/jobs/backend-developer-jobs-in-Helsinki",
    component: _0ee209f5,
    name: "jobs-backend-developer-jobs-in-Helsinki"
  }, {
    path: "/jobs/backend-developer-jobs-in-Jutland",
    component: _609bf776,
    name: "jobs-backend-developer-jobs-in-Jutland"
  }, {
    path: "/jobs/backend-developer-jobs-in-Odense",
    component: _a7e00f04,
    name: "jobs-backend-developer-jobs-in-Odense"
  }, {
    path: "/jobs/backend-developer-jobs-in-Oslo",
    component: _2a9bf4d5,
    name: "jobs-backend-developer-jobs-in-Oslo"
  }, {
    path: "/jobs/backend-developer-jobs-in-Stockholm",
    component: _e05ee240,
    name: "jobs-backend-developer-jobs-in-Stockholm"
  }, {
    path: "/jobs/business-development-roles-in-Copenhagen",
    component: _7818a9a4,
    name: "jobs-business-development-roles-in-Copenhagen"
  }, {
    path: "/jobs/business-development-roles-in-Finland",
    component: _00647c28,
    name: "jobs-business-development-roles-in-Finland"
  }, {
    path: "/jobs/business-development-roles-in-Oslo",
    component: _3361bc61,
    name: "jobs-business-development-roles-in-Oslo"
  }, {
    path: "/jobs/business-development-roles-in-Sweden",
    component: _4458b8e6,
    name: "jobs-business-development-roles-in-Sweden"
  }, {
    path: "/jobs/content-creator-in-Copenhagen",
    component: _9a94ada8,
    name: "jobs-content-creator-in-Copenhagen"
  }, {
    path: "/jobs/content-creator-in-Denmark",
    component: _ad478d70,
    name: "jobs-content-creator-in-Denmark"
  }, {
    path: "/jobs/customer-success-roles-in-Copenhagen",
    component: _6da93a8a,
    name: "jobs-customer-success-roles-in-Copenhagen"
  }, {
    path: "/jobs/customer-success-roles-in-Denmark",
    component: _727b54aa,
    name: "jobs-customer-success-roles-in-Denmark"
  }, {
    path: "/jobs/designer-jobs-in-Copenhagen",
    component: _7719c3e8,
    name: "jobs-designer-jobs-in-Copenhagen"
  }, {
    path: "/jobs/designer-jobs-in-Stockholm",
    component: _2b46fd6c,
    name: "jobs-designer-jobs-in-Stockholm"
  }, {
    path: "/jobs/devops-jobs-in-Denmark",
    component: _43d5713a,
    name: "jobs-devops-jobs-in-Denmark"
  }, {
    path: "/jobs/e-commerce-jobs-in-Copenhagen",
    component: _b55c8e18,
    name: "jobs-e-commerce-jobs-in-Copenhagen"
  }, {
    path: "/jobs/e-commerce-jobs-in-Sweden",
    component: _109df036,
    name: "jobs-e-commerce-jobs-in-Sweden"
  }, {
    path: "/jobs/financial-controller-jobs-in-Denmark",
    component: _66f2dadd,
    name: "jobs-financial-controller-jobs-in-Denmark"
  }, {
    path: "/jobs/foodtech-in-Sweden",
    component: _3e3e7888,
    name: "jobs-foodtech-in-Sweden"
  }, {
    path: "/jobs/foodtech-jobs-in-Denmark",
    component: _89f6aa1e,
    name: "jobs-foodtech-jobs-in-Denmark"
  }, {
    path: "/jobs/graphic-design-internship-in-Copenhagen",
    component: _0a0bf1ac,
    name: "jobs-graphic-design-internship-in-Copenhagen"
  }, {
    path: "/jobs/helper",
    component: _a8997940,
    name: "jobs-helper"
  }, {
    path: "/jobs/HR-roles-in-Copenhagen",
    component: _6bda6040,
    name: "jobs-HR-roles-in-Copenhagen"
  }, {
    path: "/jobs/HR-roles-in-Sweden",
    component: _34d61d82,
    name: "jobs-HR-roles-in-Sweden"
  }, {
    path: "/jobs/internships-in-Denmark",
    component: _6bad071b,
    name: "jobs-internships-in-Denmark"
  }, {
    path: "/jobs/jobs-in-tech-in-Malmo",
    component: _a48c6c32,
    name: "jobs-jobs-in-tech-in-Malmo"
  }, {
    path: "/jobs/JobSearch",
    component: _46b50014,
    name: "jobs-JobSearch"
  }, {
    path: "/jobs/marketing-internships-in-Aarhus",
    component: _18f4601c,
    name: "jobs-marketing-internships-in-Aarhus"
  }, {
    path: "/jobs/marketing-internships-in-Copenhagen",
    component: _3ad71452,
    name: "jobs-marketing-internships-in-Copenhagen"
  }, {
    path: "/jobs/marketing-internships-in-Denmark",
    component: _2b490fe2,
    name: "jobs-marketing-internships-in-Denmark"
  }, {
    path: "/jobs/marketing-internships-in-Finland",
    component: _91bdf404,
    name: "jobs-marketing-internships-in-Finland"
  }, {
    path: "/jobs/marketing-internships-in-Stockholm",
    component: _25efb334,
    name: "jobs-marketing-internships-in-Stockholm"
  }, {
    path: "/jobs/part-time-jobs-in-Copenhagen",
    component: _1f9ee2c2,
    name: "jobs-part-time-jobs-in-Copenhagen"
  }, {
    path: "/jobs/part-time-jobs-in-Helsinki",
    component: _307f141f,
    name: "jobs-part-time-jobs-in-Helsinki"
  }, {
    path: "/jobs/part-time-jobs-in-Oslo",
    component: _b44eb802,
    name: "jobs-part-time-jobs-in-Oslo"
  }, {
    path: "/jobs/part-time-jobs-in-Stockholm",
    component: _bc566c14,
    name: "jobs-part-time-jobs-in-Stockholm"
  }, {
    path: "/jobs/project-manager-jobs-in-Copenhagen",
    component: _47b7bf30,
    name: "jobs-project-manager-jobs-in-Copenhagen"
  }, {
    path: "/jobs/SaaS-jobs-in-Aarhus",
    component: _222397be,
    name: "jobs-SaaS-jobs-in-Aarhus"
  }, {
    path: "/jobs/SaaS-jobs-in-Copenhagen",
    component: _180d5dd7,
    name: "jobs-SaaS-jobs-in-Copenhagen"
  }, {
    path: "/jobs/SaaS-jobs-in-Stockholm",
    component: _ee5fcf7e,
    name: "jobs-SaaS-jobs-in-Stockholm"
  }, {
    path: "/jobs/student-job-in-Aarhus",
    component: _446d1689,
    name: "jobs-student-job-in-Aarhus"
  }, {
    path: "/jobs/student-job-in-Copenhagen",
    component: _15f66782,
    name: "jobs-student-job-in-Copenhagen"
  }, {
    path: "/jobs/student-job-in-Helsinki",
    component: _e7498e48,
    name: "jobs-student-job-in-Helsinki"
  }, {
    path: "/jobs/student-job-in-Oslo",
    component: _5b52a188,
    name: "jobs-student-job-in-Oslo"
  }, {
    path: "/jobs/student-job-in-Stockholm",
    component: _7d05864e,
    name: "jobs-student-job-in-Stockholm"
  }, {
    path: "/jobs/student-jobs-in-Sweden",
    component: _4ecd44f0,
    name: "jobs-student-jobs-in-Sweden"
  }, {
    path: "/jobs/ux-ui-designer-jobs-in-Copenhagen",
    component: _511cd4fd,
    name: "jobs-ux-ui-designer-jobs-in-Copenhagen"
  }, {
    path: "/jobs/ux-ui-designer-jobs-in-Denmark",
    component: _81b7f1d2,
    name: "jobs-ux-ui-designer-jobs-in-Denmark"
  }, {
    path: "/jobs/ux-ui-designer-jobs-in-Sweden",
    component: _8b3b0e82,
    name: "jobs-ux-ui-designer-jobs-in-Sweden"
  }, {
    path: "/partners/danske-bank-growth",
    component: _3cc7dab3,
    name: "partners-danske-bank-growth"
  }, {
    path: "/scout/CountUp",
    component: _6b2093a1,
    name: "scout-CountUp"
  }, {
    path: "/scout/ScoutCustomers",
    component: _2688ab30,
    name: "scout-ScoutCustomers"
  }, {
    path: "/scout/ScoutHero",
    component: _6e004e22,
    name: "scout-ScoutHero"
  }, {
    path: "/scout/ScoutKeypoints",
    component: _0bba085d,
    name: "scout-ScoutKeypoints"
  }, {
    path: "/scout/ScoutStartups",
    component: _411e1bea,
    name: "scout-ScoutStartups"
  }, {
    path: "/scout/ScoutWays",
    component: _17c2d759,
    name: "scout-ScoutWays"
  }, {
    path: "/startups/career-page",
    component: _0458aa26,
    name: "startups-career-page"
  }, {
    path: "/startups/faq",
    component: _55caf585,
    name: "startups-faq"
  }, {
    path: "/startups/join",
    component: _4523da6a,
    name: "startups-join"
  }, {
    path: "/startups/white-label-feed",
    component: _77fe7c9c,
    name: "startups-white-label-feed"
  }, {
    path: "/client/applicant/settings",
    component: _f31eacc8,
    name: "client-applicant-settings"
  }, {
    path: "/client/investors/new",
    component: _1c77d3e0,
    name: "client-investors-new"
  }, {
    path: "/client/startups/edit",
    component: _77751107,
    name: "client-startups-edit"
  }, {
    path: "/client/startups/jobs",
    component: _0c1b14d4,
    name: "client-startups-jobs"
  }, {
    path: "/client/startups/new",
    component: _4da8c633,
    name: "client-startups-new"
  }, {
    path: "/client/startups/profile",
    component: _0c51043c,
    name: "client-startups-profile"
  }, {
    path: "/client/startups/settings",
    component: _57567020,
    name: "client-startups-settings"
  }, {
    path: "/funding/quiz/results",
    component: _29eef1f6,
    name: "funding-quiz-results"
  }, {
    path: "/client/startups/jobs/new",
    component: _d53a8b38,
    name: "client-startups-jobs-new"
  }, {
    path: "/careers/jobs/:id?",
    component: _58a46046,
    name: "careers-jobs-id"
  }, {
    path: "/client/investors/:investor?",
    component: _7b384bac,
    name: "client-investors-investor"
  }, {
    path: "/client/startups/:company?",
    component: _53649ffe,
    name: "client-startups-company"
  }, {
    path: "/jobs/location/:country?",
    component: _19903d3a,
    name: "jobs-location-country"
  }, {
    path: "/startups/joblistwidget/:id?",
    component: _b925f88e,
    name: "startups-joblistwidget-id"
  }, {
    path: "/whiteLabelFeed/jobs/:id?",
    component: _e332dd5c,
    name: "whiteLabelFeed-jobs-id"
  }, {
    path: "/client/investors/:investor?/edit",
    component: _673f5be2,
    name: "client-investors-investor-edit"
  }, {
    path: "/client/investors/:investor?/profile",
    component: _9b63f0fe,
    name: "client-investors-investor-profile"
  }, {
    path: "/client/investors/:investor?/settings",
    component: _70f9d57b,
    name: "client-investors-investor-settings"
  }, {
    path: "/client/investors/:investor?/startups",
    component: _30931d1e,
    name: "client-investors-investor-startups"
  }, {
    path: "/client/investors/:investor?/users",
    component: _07cdb460,
    name: "client-investors-investor-users"
  }, {
    path: "/client/startups/:company?/applicants",
    component: _1441da96,
    name: "client-startups-company-applicants"
  }, {
    path: "/client/startups/:company?/blog",
    component: _780e7db4,
    name: "client-startups-company-blog"
  }, {
    path: "/client/startups/:company?/deals",
    component: _d19b0b14,
    name: "client-startups-company-deals"
  }, {
    path: "/client/startups/:company?/edit",
    component: _4ae48b8e,
    name: "client-startups-company-edit"
  }, {
    path: "/client/startups/:company?/funding",
    component: _38050928,
    name: "client-startups-company-funding"
  }, {
    path: "/client/startups/:company?/jobs",
    component: _4870a246,
    name: "client-startups-company-jobs"
  }, {
    path: "/client/startups/:company?/profile",
    component: _0634a855,
    name: "client-startups-company-profile"
  }, {
    path: "/client/startups/:company?/settings",
    component: _cc3161b2,
    name: "client-startups-company-settings"
  }, {
    path: "/client/startups/:company?/users",
    component: _ce630b98,
    name: "client-startups-company-users"
  }, {
    path: "/client/startups/:company?/jobs/new",
    component: _5018cd2a,
    name: "client-startups-company-jobs-new"
  }, {
    path: "/client/investors/:investor?/startups/:id",
    component: _40641c19,
    name: "client-investors-investor-startups-id"
  }, {
    path: "/client/startups/:company?/deals/:id",
    component: _7ee8a25e,
    name: "client-startups-company-deals-id"
  }, {
    path: "/client/startups/:company?/funding/:id",
    component: _4d692e58,
    name: "client-startups-company-funding-id"
  }, {
    path: "/client/startups/:company?/jobs/:job",
    component: _c5365ff4,
    name: "client-startups-company-jobs-job"
  }, {
    path: "/client/startups/:company?/jobs/:job/boost",
    component: _54f6fa57,
    name: "client-startups-company-jobs-job-boost"
  }, {
    path: "/client/startups/:company?/jobs/:job/boosted",
    component: _4fdeabb6,
    name: "client-startups-company-jobs-job-boosted"
  }, {
    path: "/client/startups/:company?/jobs/:job/edit",
    component: _d0cb8af4,
    name: "client-startups-company-jobs-job-edit"
  }, {
    path: "/client/startups/:company?/jobs/:job/error",
    component: _3daa2388,
    name: "client-startups-company-jobs-job-error"
  }, {
    path: "/client/startups/:company?/jobs/:job/headhunter-posted",
    component: _408a0680,
    name: "client-startups-company-jobs-job-headhunter-posted"
  }, {
    path: "/client/startups/:company?/jobs/:job/hh-steps",
    component: _35d332d4,
    name: "client-startups-company-jobs-job-hh-steps"
  }, {
    path: "/client/startups/:company?/jobs/:job/max-posted",
    component: _2a26ac44,
    name: "client-startups-company-jobs-job-max-posted"
  }, {
    path: "/client/startups/:company?/jobs/:job/monetise",
    component: _3ead4d48,
    name: "client-startups-company-jobs-job-monetise"
  }, {
    path: "/client/startups/:company?/jobs/:job/posted",
    component: _6396fe3b,
    name: "client-startups-company-jobs-job-posted"
  }, {
    path: "/client/startups/:company?/jobs/:job/posted-via-invoice",
    component: _bffe8948,
    name: "client-startups-company-jobs-job-posted-via-invoice"
  }, {
    path: "/jobs/location/:country?/:city",
    component: _6ab975c4,
    name: "jobs-location-country-city"
  }, {
    path: "/deals/:id",
    component: _05255b0c,
    name: "deals-id"
  }, {
    path: "/funding/:id",
    component: _6d36fc20,
    name: "funding-id"
  }, {
    path: "/jobs/:id",
    component: _2ff514ac,
    name: "jobs-id"
  }, {
    path: "/profile-settings/:id?",
    component: _689b4f5a,
    name: "profile-settings-id"
  }, {
    path: "/startups/:id",
    component: _64c36dec,
    name: "startups-id"
  }, {
    path: "/tools/:id",
    component: _570ff6ce,
    name: "tools-id"
  }, {
    path: "/jobs/:id/resend-applicants",
    component: _7c1ed246,
    name: "jobs-id-resend-applicants"
  }, {
    path: "/jobs/:id/welcome",
    component: _14ab9288,
    name: "jobs-id-welcome"
  }, {
    path: "/startups/:id/welcome",
    component: _30473ffc,
    name: "startups-id-welcome"
  }, {
    path: "/",
    component: _2e1dbe1a,
    name: "index",
    children: [{
      path: "AnimatedImage",
      component: _73e0d7a3,
      name: "index-AnimatedImage"
    }, {
      path: "ExploreByCategory",
      component: _a9b5bbda,
      name: "index-ExploreByCategory"
    }, {
      path: "FeaturedStartups",
      component: _c2584fee,
      name: "index-FeaturedStartups"
    }, {
      path: "homePageHelpers",
      component: _991cbaa6,
      name: "index-homePageHelpers"
    }, {
      path: "HomePageHero",
      component: _44b9edcd,
      name: "index-HomePageHero"
    }, {
      path: "PopularStartups",
      component: _314ab97a,
      name: "index-PopularStartups"
    }, {
      path: "StartupCarousel",
      component: _eee1dc30,
      name: "index-StartupCarousel"
    }, {
      path: "StartupLogos",
      component: _487ce6d0,
      name: "index-StartupLogos"
    }]
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
