/*
import Vue from 'vue';
import Router from 'vue-router';
import startsWith from 'lodash/startsWith';
import replace from 'lodash/replace';

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options;
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host;

  const isWhiteLabel = ssrContext ? ssrContext.req.headers['x-whitelabel'] === 'true' : false;

  return new Router({
    ...options,
    routes: resolveRoutes(options.routes, hostname, isWhiteLabel)
  });
}

function resolveRoutes(defaultRoutes, hostname, isWhiteLabel) {
  hostname = hostname.replace(/^(www\.)/, '');

  const thehubDomains = [
    'thehub.io',
    'hubtest.io',
    'thehub-frontend--staging.herokuapp.com'
    // 'localhost:8000'
  ];

  const whiteLabelDomains = ['whitelabel.com', 'partner.example.com'];

  if (isWhiteLabel || hostname === 'localhost:8000') {
    return whiteLabelFeedRoutes(defaultRoutes);
  } else if (thehubDomains.includes(hostname)) {
    return thehubRoutes(defaultRoutes);
  } else {
    return careerRoutes(defaultRoutes);
  }
}

function thehubRoutes(routes) {
  return routes.filter((r) => !startsWith(r.name, 'careers'));
}

function careerRoutes(routes) {
  return routes
    .filter((r) => startsWith(r.name, 'careers'))
    .map((r) => ({
      ...r,
      path: r.path == '/careers' ? '/' : replace(r.path, '/careers', '')
    }));
}

function whiteLabelFeedRoutes(routes) {
  return routes
    .filter((r) => startsWith(r.name, 'whiteLabelFeed'))
    .map((r) => ({
      ...r,
      path: r.path == '/whiteLabelFeed' ? '/' : replace(r.path, '/whiteLabelFeed', '')
    }));
}
*/

import Vue from 'vue';
import Router from 'vue-router';
import startsWith from 'lodash/startsWith';
import replace from 'lodash/replace';

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options;
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host;

  // console.log('ssrContext.req.headers', ssrContext.req.headers);
  const isWhiteLabel = ssrContext ? ssrContext.req.headers['x-whitelabel'] === 'true' : false;

  return new Router({
    ...options,
    routes: resolveRoutes(options.routes, hostname, isWhiteLabel)
  });
}

function resolveRoutes(defaultRoutes, hostname, isWhiteLabel) {
  hostname = hostname.replace(/^(www\.)/, '');

  const thehubDomains = ['thehub.io', 'hubtest.io', 'localhost:8000'];

  if (isWhiteLabel || hostname === 'localhost:8000' || hostname === 'wlf.hubtest.io') {
    // if (isWhiteLabel) {
    const wlfRoutes = whiteLabelFeedRoutes(defaultRoutes);
    console.log('wlfRoutes', wlfRoutes);
    return wlfRoutes;
  } else if (thehubDomains.includes(hostname)) {
    return thehubRoutes(defaultRoutes);
  } else {
    return careerRoutes(defaultRoutes);
  }
}

function thehubRoutes(routes) {
  return routes.filter(
    (r) => !startsWith(r.name, 'careers') && !startsWith(r.name, 'whiteLabelFeed')
  );
}

function careerRoutes(routes) {
  return routes
    .filter((r) => startsWith(r.name, 'careers'))
    .map((r) => ({
      ...r,
      path: r.path == '/careers' ? '/' : replace(r.path, '/careers', '')
    }));
}

function whiteLabelFeedRoutes(routes) {
  return routes
    .filter((r) => startsWith(r.name, 'whiteLabelFeed'))
    .map((r) => ({
      ...r,
      path: r.path == '/whiteLabelFeed' ? '/' : replace(r.path, '/whiteLabelFeed', '')
    }));
}
